import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL || '';

export const verifyTgData = async (initData) => {

    const controller = new AbortController();
    const timeout = setTimeout(() => {
        controller.abort(); // Aborts the request after the timeout
    }, 10000); // Set a 10-second timeout for the request

    try {
        const response = await fetch(`${apiUrl}/api/verify-telegram-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ initData }),
            signal: controller.signal, // Attach the abort controller to the request
        });

        clearTimeout(timeout); // Clear the timeout once the request completes

        if (!response.ok) {
            // Handle HTTP errors (e.g., 404, 500)
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        return { success: true, ...data };
    } catch (error) {
        if (error.name === 'AbortError') {
            console.error('Request timed out');
        } else {
            console.error('Error verifying Telegram data:', error.message || error);
        }

        // Optionally return a default response or handle the error as needed
        return { success: false, message: error.message || 'Failed to verify Telegram data' };
    }
};

export const updateProject = async (projectData) => {
    try {
        const response = await axios.put(
            `${apiUrl}/api/edit-project`,
            {
                ...projectData
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating project:', error);
        throw error;
    }
};

export const fetchUserProjects = async (userId) => {
    try {
        const response = await axios.get(`${apiUrl}/api/projects`, {
            headers: {
                'user-id': userId // Pass the user ID in headers
            }
        });

        return response.data.projects; // Return the projects data
    } catch (error) {
        console.error('Error fetching projects:', error);
        throw error; // Throw the error to be handled in the calling component
    }
};

export const updateUserBalance = async (userId, promptId, promptName, projectName, tokensAmount, cash) => {
    try {
        const response = await fetch(`${apiUrl}/api/update-balance`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, promptId, promptName, projectName, tokensAmount, cash }),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();  // Parse the JSON response
        return data;
    } catch (error) {
        console.error('Error updating balance:', error);
        throw error; // Throw the error to be handled in the calling component
    }
};