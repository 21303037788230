import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/library');
}, [navigate]);

  return (
    <div className='wrapper'>
      <div className='wrapper1'>
      </div>
    </div>
  );
};

export default Home;
