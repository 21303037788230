import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';
import LoadingState from '../components/LoadingState';
import './Projects.css';

const Projects = () => {
    const navigate = useNavigate();
    const { projects, loading, error } = useContext(Context);
    
    if (loading) {
        return <LoadingState />;
    }

    if (error) {
        return <div>{error}</div>;
    }
    
    return (
        <div className='wrapper'>
            <div className='padding-top'></div>
            <div className='wrapper1'>
                <div className="tab-block-header">
                    <h1>Мои проекты</h1>
                </div>
                <div className="projects-grid">
                    {projects.map((project, index) => (
                        <div
                            key={index}
                            className="grid-item"
                            onClick={() => navigate('/edit-project', { state: { projectId: project.id, projectName: project.name, projectDescription: project.description } })} // Navigate to edit project with the project id
                        >
                            <div className="grid-project-name">{project.name}</div>
                        </div>
                    ))}
                    <div className="grid-item new-project" onClick={() => navigate('/create-project')}>
                        <h3 style={{textAlign: 'center'}}>+ Новый проект</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
