// Wait.js
import React from 'react';

const LoadingState = () => {
    return (
        <div>
            <h1>Loading...</h1>
            <p>Please wait while we load your data.</p>
        </div>
    );
};

export default LoadingState;
