import React, { useRef, useState, useContext, useEffect } from 'react';
import { Context } from '../context/GlobalContext';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import './OnboardingSlider.css';

const OnboardingSlider = () => {
    const sliderRef = useRef(null);
    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);
    const { selectedProject } = useContext(Context);

    useEffect(() => {
        if (selectedProject) {
            console.log('selectedProject');
        } else {
            console.log('no selectedProject');
        }
    }, [selectedProject]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    const slides = [
        {
            images: ["/assets/ai-thingy.png"], // Single image slide
            title: "GPT-ассистент с библиотекой промптов для маркетологов",
            description: "Добро пожаловать в Штучку-Интеллект! Этот ИИ-ассистент поможет вам с рутиной, креативом и стратегией, чтобы вы экономили время и сосредоточились на главном",
        },
        {
            images: ["/assets/slide1.png"], // Two images slide
            title: "Добавьте проект и введите описание",
            description: "Описание вашего проекта автоматически добавляется в каждый промпт, позволяя избежать повторного ввода данных.",
        },
        {
            images: ["/assets/slide2.png"], // Three images slide
            title: "Решения для любых задач",
            description: "Выберите подходящую категорию в каталоге и найдите промпт, идеально соответствующий вашей задаче.",
        },
        {
            images: ["/assets/slide3.png"], // Single image slide
            title: "Добавьте информацию для точной генерации",
            description: "В каждом промпте есть уточняющий вопрос. Чем точнее ответ, тем качественнее результат.",
        },
        {
            images: ["/assets/slide4.png"], // Two images slide
            title: "Получайте готовые решения одним кликом",
            description: "Просто нажмите кнопку «Сгенерировать», и наша модель создаст для вас нужный контент. PDF-файл с ответом отправится вам в бот.",
        },
        {
            images: ["/assets/balance.png"], // Two images slide
            title: "Платите только за использование",
            description: "Генерации стоят токенов. Когда они закончатся, купите токены, нажав на баланс. Нет месячных подписок — токены не сгорают. 3 000 токенов в подарок на первые эксперименты.",
        },
    ]; 

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const handleSkip = () => {
        if (selectedProject) {
            navigate('/library');
        } else {
            navigate('/onboarding');
        }
    };

    return (
        <div className="onboarding-slider">
            <Slider ref={sliderRef} {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div className="slide-images">
                            {slide.images.map((image, idx) => (
                                <div
                                    key={idx}
                                    className={`slide-image-container slide-${index} image-${idx}`}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <img
                                        src={image}
                                        alt={`Slide ${index + 1} Image ${idx + 1}`}
                                        className={`slide-image slide-${index}-image-${idx}`}
                                    />
                                </div>
                            ))}
                        </div>

                        <h2 className="slide-title">{slide.title}</h2>
                        <p className="slide-description">{slide.description}</p>
                    </div>
                ))}
            </Slider>
            <div className="fixed-buttons">
                {currentSlide < slides.length - 1 ? (
                    <>
                        <button className="skip-button" onClick={handleSkip}>Пропустить</button>
                        <button className="next-button" onClick={nextSlide}>Далее</button>
                    </>
                ) : (
                    <button className="start-button" onClick={handleSkip}>Начать пользоваться</button>
                )}
            </div>
        </div>
    );
};

export default OnboardingSlider;
