import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Context } from '../context/GlobalContext';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { getTokenDeclension } from '../utils/declension';
import './AppBar.css'; // Import your styles for the AppBar

const AppBar = () => {
    const navigate = useNavigate();
    const { user, projects, selectedProject, setSelectedProject } = useContext(Context);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sideMenuOpen, setSideMenuOpen] = useState(false); // State to toggle side menu
    const dropdownRef = useRef(null); // Create a ref for the dropdown
    const [balance, setBalance] = useState('');

    useEffect(() => {
        if (user && user.token_amount) {
            setBalance(user.token_amount);
        }

    }, [user, balance, setBalance]);

    // Initialize selectedProject if projects exist
    useEffect(() => {
        if (projects.length > 0 && !selectedProject) {
            setSelectedProject(projects[0]); // Set to first project if none is selected
        }
    }, [projects, selectedProject, setSelectedProject, user]);

    const handleProjectChange = (project) => {
        if (project === '+ add new') {
            navigate('/create-project');
        } else {
            setSelectedProject(project); // Update selected project in context
        }
        setDropdownOpen(false); // Close the dropdown after selection
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleSideMenu = () => {
        setSideMenuOpen(!sideMenuOpen);
    };

    const toggleNavigateProjects = () => {
        navigate('/projects');
        toggleSideMenu();
    };

    const toggleNavigateAssistants = () => {
        navigate('/library');
        toggleSideMenu();
    };

    const toggleNavigateBalance = () => {
        navigate('/tariffs');
        toggleSideMenu();
    };

    const toggleNavigateSlider = () => {
        navigate('/onboarding-slider');
        toggleSideMenu();
    };

    const toggleNavigatePrivacy = () => {
        navigate('/privacy');
        toggleSideMenu();
    };

    const toggleNavigateOffer = () => {
        navigate('/offer');
        toggleSideMenu();
    };

    const toggleNavigateReferral = () => {
        navigate('/tariffs?tab=tab2');
        toggleSideMenu();
    };

    // Close the dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <>
            {sideMenuOpen && <div className="blur-overlay" onClick={toggleSideMenu}></div>}

            <div className={`side-menu ${sideMenuOpen ? 'open' : ''}`}>
                <div className="side-menu-content">
                    <div className='menu-top'>
                        <div style={{ color: '#333', marginTop: '50px' }}><span style={{ fontWeight: 'bold', fontSize: '26px', lineHeight: '0' }}>Штучка</span><span style={{ fontSize: '20px', lineHeight: '0' }}> - интеллект</span></div>
                        <div style={{ color: '#333', marginTop: '10px', fontSize: '14px', lineHeight: '1.4' }}>Полезная штучка, разработанная маркетологами для маркетологов и предпринимателей</div>
                    </div>
                    <div className='menu-middle'>
                        <button style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: '10px', cursor: 'pointer' }} onClick={toggleNavigateProjects}>
                            <div>Мои проекты</div>
                            <TrendingFlatRoundedIcon />
                        </button>
                        <button style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: '10px', cursor: 'pointer' }} onClick={toggleNavigateAssistants}>
                            <div>Библиотека штучек</div>
                            <TrendingFlatRoundedIcon />
                        </button>
                        <button style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: '10px', cursor: 'pointer' }} onClick={toggleNavigateBalance}>
                            <div>Мой баланс</div>
                            <TrendingFlatRoundedIcon />
                        </button>
                        <button style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: '10px', cursor: 'pointer' }} onClick={toggleNavigateSlider}>
                            <div>Как это работает</div>
                            <TrendingFlatRoundedIcon />
                        </button>
                        <button style={{ display: 'flex', alignItems: 'center', textAlign: 'left', gap: '10px', cursor: 'pointer' }} onClick={toggleNavigateReferral}>
                            <div>Реферальная программа</div>
                            <TrendingFlatRoundedIcon />
                        </button>
                    </div>
                    <div className='menu-bottom'>
                        <div style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '20px' }}>
                            <a href="https://t.me/shtuchka_intellect" target="_blank" rel="noopener noreferrer">
                                <img className='menu-social-item' src='/social/icon-telegram.png' style={{ height: '30px' }} alt='telegram-logo' />
                            </a>
                            <a href="https://vc.ru/u/1499491-vladimir-semakin" target="_blank" rel="noopener noreferrer">
                                <img className='menu-social-item' src='/social/icon-vc.png' style={{ height: '30px' }} alt='vc-logo' />
                            </a>
                            <a href="https://vk.com/shtuchka_intellect" target="_blank" rel="noopener noreferrer">
                                <img className='menu-social-item' src='/social/icon-vk.png' style={{ height: '30px' }} alt='vk-logo' />
                            </a>
                        </div>
                        <button onClick={toggleNavigateOffer} style={{ cursor: 'pointer' }}>Публичная оферта</button>
                        <button onClick={toggleNavigatePrivacy} style={{ cursor: 'pointer' }}>Политика конфиденциальности</button>
                        <div>ИП Ложкина Вера Александровна<br />ИНН 1234567890 ОГРНИП 1234567890 <a style={{ textDecoration: 'none', color: '#333' }} href="mailto:akane@inbox.ru" target="_blank" rel="noopener noreferrer">akane@inbox.ru</a></div>
                    </div>
                </div>
            </div>
            <div className="appbar">
                <div className='appbar-wrapper'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', alignItems: 'center' }}>
                        <button className='menu-button' onClick={toggleSideMenu}>
                            <DragHandleIcon />
                        </button>
                        {/*<div className="custom-dropdown" ref={dropdownRef}>
                            <div className='dropdown-button-wrapper'>
                                <button className="dropdown-button" onClick={toggleDropdown}>
                                    <div className='dropdown-text-wrapper'>
                                        <div className='dropdown-text'>{selectedProject ? selectedProject.name : 'Select Project'}</div>
                                    </div>
                                    <KeyboardArrowDownRoundedIcon style={{ opacity: 0.9 }} />
                                </button>
                            </div>
                            {dropdownOpen && (
                                <ul className="dropdown-list">
                                    {projects.map((project, index) => (
                                        <li key={index} style={{ fontWeight: '500' }} className="dropdown-item" onClick={() => handleProjectChange(project)}>
                                            {project.name}
                                        </li>
                                    ))}
                                    <li className="dropdown-item" style={{ paddingBottom: '10px', fontWeight: '500' }} onClick={() => handleProjectChange('+ add new')}>
                                        + Новый проект
                                    </li>
                                </ul>
                            )}
                        </div>*/}
                    </div>
                    <div className="appbar-navigation">
                        {user ? ( // Conditional rendering
                            <button style={{ padding: '0', display: 'flex', alignItems: 'center', gap: '10px' }} onClick={() => navigate('/tariffs')}>
                                <span style={{lineHeight: '0'}}><img src="/icons/coin.png" style={{width: '20px'}} /></span><span style={{ fontWeight: '500', textDecoration: 'underline' }}> {balance}</span>
                            </button>
                        ) : (
                            <div>Loading</div> // Show loading state
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppBar;
