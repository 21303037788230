import { createGlobalStyle } from 'styled-components';
import { useContext } from 'react';
import { TelegramThemeContext } from './context/TelegramThemeContext';

const GlobalStyles = createGlobalStyle`


  .bg-color {
    background-color: ${({ theme }) => theme.bg_color};
  }

    .under-header {
        color: ${({ theme }) => theme.text_color};
    }

    .hint {
        color: ${({ theme }) => theme.hint_color};
    }

    .text {
        color: ${({ theme }) => theme.text_color} !important;
    }

  .background-button-color {
    background-color: ${({ theme }) => theme.button_color};
  }

  a {
    color: ${({ theme }) => theme.link_color};
  }

  .font {
    font-family: "Jost", sans-serif;
  }

`;

const ThemedGlobalStyle = () => {
  const theme = useContext(TelegramThemeContext);
  return <GlobalStyles theme={theme} />;
};

export default ThemedGlobalStyle;
