import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './CreateProject.css';
import LoadingState from '../components/LoadingState';

const ProjectForm = () => {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { user, projects, setProjects, setSelectedProject: setGlobalSelectedProject } = useContext(Context);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const [isFieldFocused, setIsFieldFocused] = useState(false);
    const bottomRef = useRef(null);
    const navigate = useNavigate();

    const inputRef = useRef(null);
    const textareaRef = useRef(null);

    const maxNameLength = 30;
    const maxDescriptionLength = 2500;

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [projectDescription]);

    const handleFocus = () => {
        setFilteredProjects(projects);
        setIsFieldFocused(true);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    const handleBlur = () => {
        setIsFieldFocused(false);
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        setProjectName(inputValue);

        const filtered = projects.filter((project) =>
            project.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredProjects(filtered);
        setSelectedProject(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isDuplicateName = projects.some((project) => project.name.toLowerCase() === projectName.toLowerCase());

        if (isDuplicateName) {
            setError('Проект с таким названием уже существует. Пожалуйста, выберите другое название.');
            return;
        }

        setError('');
        setSuccessMessage('');
        setLoading(true);

        const newProject = {
            name: projectName,
            description: projectDescription,
            user_id: user.id,
            is_active: true
        };

        try {
            const response = await fetch(`${apiUrl}/api/add-project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newProject)
            });

            const result = await response.json();

            if (response.ok && result.message === 'OK') {
                setSuccessMessage('Проект успешно создан!');

                setProjects(prevProjects => {
                    const updatedProjects = [...prevProjects, result.project];
                    setGlobalSelectedProject(result.project);
                    return updatedProjects;
                });

                setTimeout(() => navigate('/projects'), 2000);
            } else {
                setError('Ошибка при создании проекта. Попробуйте еще раз.');
            }
        } catch (error) {
            console.error('Error creating project:', error);
            setError('Ошибка сети. Попробуйте позже.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='wrapper'>
            <div className='padding-top'></div>
            <div className='wrapper1'>
                {loading ? (
                    <LoadingState />
                ) : (

                    <form onSubmit={handleSubmit} className="project-form">
                        <div className="tab-block-header" onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginBottom: '20px' }}>
                            <div style={{ lineHeight: '0' }}><img style={{ marginRight: '10px', width: '24px', height: '24px' }} src='/icons/arrow-left.png' /></div>
                            <div>Назад</div>
                        </div>
                        <h1>Новый проект</h1>
                        <div className="form-group" style={{ marginTop: '0' }}>
                            <label htmlFor="projectName">Название проекта</label>
                            <div className='input-wrapper' style={{ position: 'relative' }}>
                                <input className='create-project-input'
                                    type="text"
                                    id="projectName"
                                    ref={inputRef}
                                    value={projectName}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    maxLength={maxNameLength}
                                    placeholder="Мой проект"
                                    required
                                />
                                <span
                                    className="remaining-characters"
                                    style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        right: '10px',
                                        fontSize: '12px',
                                        color: '#999'
                                    }}
                                >
                                    {maxNameLength - projectName.length}/{maxNameLength}
                                </span>
                            </div>
                            <div className='label-description'>Для списка проектов</div>
                        </div>
                        {/* <div>
                            //Render buttons for filtered projects 
                            {filteredProjects.length > 0 && (
                                <div className="project-buttons">
                                    {filteredProjects.map((project) => (
                                        <button
                                            key={project.id}
                                            type="button"
                                            className="project-button"
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onClick={() => handleProjectSelect(project)}
                                        >
                                            {project.name}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>*/}

                        <div className="form-group">
                            <label htmlFor="projectDescription">Описание проекта</label>
                            <div className='input-wrapper' style={{ position: 'relative' }}>
                                <textarea className='create-project-input1'
                                    title="Title"
                                    id="projectDescription"
                                    ref={textareaRef}
                                    value={projectDescription}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={(e) => setProjectDescription(e.target.value)}
                                    maxLength={maxDescriptionLength}
                                    placeholder="Опишите проект, его уникальные особенности, боли и страхи целевой аудитории."
                                    required
                                />
                                <span
                                    className="remaining-characters"
                                    style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        right: '10px',
                                        fontSize: '12px',
                                        color: '#999'
                                    }}
                                >
                                    {maxDescriptionLength - projectDescription.length}/{maxDescriptionLength}
                                </span>
                            </div>
                            <div className='label-description'>Этот знание будет учитываться при генерации контента</div>
                        </div>
                        <button
                            type="submit"
                            className="action-button"
                            ref={bottomRef}
                            style={{
                                marginBottom: isFieldFocused ? '400px' : '0px',
                            }}>
                            Сохранить
                        </button>

                        {successMessage && <p className="success-message">{successMessage}</p>}
                        {error && <p className="error-message">{error}</p>}
                    </form>
                )}
            </div>
        </div>
    );
};

export default ProjectForm;
