import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Context } from '../context/GlobalContext';

const Confirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Destructure state passed from the previous page
    const { id, name, description, field, fieldValue, hint } = location.state || {};
    const { user, selectedProject } = useContext(Context);
    const [pdfSent, setPdfSent] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [streamedData, setStreamedData] = useState('');
    const [fullResponse, setFullResponse] = useState('');
    const [error, setError] = useState(null);

    const handleNavigateBack = () => {
        // Navigate back and pass state variables (fields) back to the previous page
        navigate('/assistant', {
            state: {
                id,
                name,
                description,
                field,
                hint,
                fieldValue
            }
        });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setStreamedData('');  // Clear previous streamed data
        setError(null);
        const stream = true;
    
        try {
            const response = await fetch(`${apiUrl}/api/receive-prompt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id,
                    selectedProject,
                    fieldValue,
                    stream
                })
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.log(errorData);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
    
            // Use this to process chunks as they come in and display real-time updates
            let streamedResponse = '';
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
    
                const chunk = decoder.decode(value, { stream: true });
                streamedResponse += chunk;  // Append the current chunk to the accumulated response
    
                // Update the state with the new chunk so the user sees the streamed data in real time
                setStreamedData((prev) => prev + chunk);
            }

            console.log("Full Response:", streamedResponse);
            setFullResponse(streamedResponse);
            setIsLoading(false);
        } catch (error) {
            console.error('Error while streaming:', error);
            setError('An error occurred while fetching the response.');
            setIsLoading(false);  // Stop loading in case of error
        }
    };

    const handleRegenerate = () => {
        // Call handleSubmit again to regenerate the response
        handleSubmit();
    };

    const handleGetPdf = async () => {
        setIsButtonLoading(true);
        const chatId = user.telegram_id;
        console.log(chatId, fullResponse)
        try {
            const response = await fetch(`${apiUrl}/api/getPdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chatId: chatId,
                    name: name,
                    content: fullResponse
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setPdfSent(true);
            }
            catch (error) {
            console.error('Error while generating pdf:', error);
            setIsButtonLoading(false);
        }
    } 

    return (
        <div className="wrapper">
            <div className="wrapper1">
                <div className="template-block">
                    <div className="tab-block-header">
                        <div
                            style={{ marginRight: '10px', cursor: 'pointer', transform: 'translateY(4px)' }}
                            onClick={handleNavigateBack}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </div>
                        <h1>Подтверждение</h1>
                    </div>
                    <div className="confirmation-content" style={{ marginBottom: '20px' }}>
                        <div><span style={{ opacity: '0.7' }}>Проект: </span><span style={{ fontWeight: '500', fontSize: '18px' }}>{selectedProject.name}</span></div>
                        <div><span style={{ opacity: '0.7' }}>Штучка: </span><span style={{ fontWeight: '500', fontSize: '18px' }}>{name}</span></div>
                        <div><span style={{ opacity: '0.7' }}>Доп. поле: </span><span style={{ fontWeight: '500', fontSize: '18px' }}>{fieldValue}</span></div>
                    </div>
                </div>

                {/* Final Submit Button */}
                {!fullResponse && !isLoading && (
                    <button type="submit" className="gradient-button" onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? 'Загрузка...' : 'Начать'}
                    <span className="gradient-button-icon">✨</span>
                </button>)}

                {/* Loading spinner */}
                {isLoading && <div className="loading-spinner">Loading...</div>}

                {/* Streamed data display */}
                {streamedData && (
                    <div>
                        <div className="streamed-response" style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                            <h3>Streaming Data:</h3>
                            <p>{streamedData}</p>
                        </div>
                        {error && (
                            <div>
                                <div className="error-message">{error}</div>
                                <button className='action-button' onClick={handleRegenerate}>
                                    {isButtonLoading ? 'Загрузка...' : 'Попробовать снова'}
                                </button>
                            </div>
                        )}
                        {(fullResponse) && !pdfSent && (
                            <button className='action-button' onClick={handleGetPdf}>
                                {isButtonLoading ? 'Загрузка...' : 'Скачать PDF'}
                            </button>
                        )}
                        {pdfSent && (
                            <div className="pdf-sent-message">Отправлено в бот</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Confirmation;