import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StatusBar from '../components/StatusBar';

const Library = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [prompts, setPrompts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const [error, setError] = useState('');


  const tabs = useMemo(() => ['Все штучки', 'Интернет-реклама', 'SMM', 'Контент-маркетинг', 'Брендинг и стратегия'], []);


  const fetchPrompts = useCallback(async (currentPage) => {
    try {
      setLoading(true);
      const category = tabs[activeTab];

      const params = { page: currentPage, limit: 1000 };
      if (category !== 'Все штучки') {
        params.category = category;
      }

      const response = await axios.get(`${apiUrl}/api/prompts`, { params });
      const newPrompts = response.data;

      if (newPrompts.length === 0) {
        setHasMore(false);
      } else {
        setPrompts((prev) => (currentPage === 1 ? newPrompts : [...prev, ...newPrompts]));
        setHasMore(true);
      }
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setError('Error loading data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [activeTab, apiUrl, tabs]);

  useEffect(() => {
    setPage(1);
    fetchPrompts(1);
  }, [activeTab, fetchPrompts]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleBlockClick = (block) => {
    navigate('/assistant', {
      state: {
        id: block.id,
        name: block.name,
        description: block.description,
        hint: block.hint,
        prompt: block.prompt,
        prompt_length: block.prompt_length,
        placeholder: block.placeholder,
        answer_example: block.answer_example
      },
    });
  };

  return (
    <div className='wrapper'>
      <div className="padding-top"></div>
      {/*<StatusBar />*/}
      <h1 style={{margin: '0 20px 20px 20px'}}>Библиотека штучек</h1>
      <div className='tabs-scroll-container'>
      <div className="tabs-scroll">
        {tabs.map((tab, index) => (
          <button
            key={index}
            id={`tab-${index}`}
            className={`tab-item ${activeTab === index ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            #{tab}
          </button>
        ))}
      </div>
      </div>

      <div className='wrapper1' style={{ marginTop: '20px' }}>

      <div className={`tabs-container ${loading ? 'loading' : ''}`}>

          <div className="tab-content">
            {prompts.map((block, index) => (
              <div key={index} className="tab-block" onClick={() => handleBlockClick(block)}>
                <div className='tab-block-header'>
                  <h3 style={{fontFamily: '"Inter", sans-serif'}}>{block.name}</h3>
                </div>
                <p>{block.description}</p>
                <p style={{fontSize: '12px', opacity: '0.7'}}>~{block.prompt_length} <span><img src="/icons/coin.png" style={{width: '10px', transform: 'translateY(1px)'}} /></span></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Library;
