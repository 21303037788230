import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';

const Referral = () => {
    const navigate = useNavigate();
    const { user } = useContext(Context);

    const handleShare = async () => {
        const url = `https://t.me/aithingybot/aithingy&startapp=${user.id}`;
        if (navigator.share) {
            // Native share supported
            try {
                await navigator.share({
                    title: 'Check this out!',
                    text: 'I found something interesting:',
                    url: url,
                });
                console.log('Content shared successfully!');
            } catch (err) {
                console.error('Error sharing:', err);
            }
        } else {
            // Fallback to copying the link
            try {
                await navigator.clipboard.writeText(url || window.location.href);
                alert('Link copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy the link:', err);
                alert('Failed to copy the link. Please copy it manually.');
            }
        }
    };

    return (
        <div className='wrapper'>
            <div className="padding-top"></div>
        <div className='wrapper1'>
        <button onClick={handleShare} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
            Share
        </button>
        </div>
      </div>

    );
};

export default Referral;
