import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const TelegramThemeContext = createContext();

// Create a provider component
export const TelegramThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    bg_color: '#ffffff',
    text_color: 'rgb(38,38,38)',
    button_color: '#0088cc',
    button_text_color: '#ffffff',
    button_radius: '10px',
    hint_color: '#cccccc',
    link_color: '#0088cc',
  });
  
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      setTheme(tg.themeParams || theme);

      // Listen for theme changes
      tg.onEvent('themeChanged', () => {
        setTheme(tg.themeParams || theme);
      });
    }
  }, []);

  return (
    <TelegramThemeContext.Provider value={theme}>
      {children}
    </TelegramThemeContext.Provider>
  );
};
