import React from 'react';

const Offer = () => {

    return (
        <div className='wrapper'>
            <div className='padding-top'></div>
            <div className='wrapper1'>
                <h1 style={{ marginBottom: '4px', lineHeight: 1.4 }}>Оферта на использование сервиса "Штучка-Интеллект"</h1>
                <p style={{ textAlign: 'left', fontSize: '12px', margin: '0 0 20px 0', padding: '0' }}>Утверждено 7 октября 2024г.</p>
                <p>Настоящая оферта (далее — Оферта) является официальным предложением в адрес пользователей веб-сайта <a href="https://штучка-интеллект.рф">https://штучка-интеллект.рф</a>, всех его разделов, подразделов, субдоменов (далее — Сайт), а также Телеграм-бота <a href="https://t.me/aithingybot">https://t.me/aithingybot</a> (далее — Бот), (совместно именуемых далее — Сервис), заключить договор на использование Сервиса на условиях, изложенных ниже.</p>
                
                <h2>1. Термины и определения</h2>

                <p>1.1. Пользователь — любое лицо, получившее доступ к Сервису и использующее его.</p>
                <p>1.2. Администратор — лицо, осуществляющее управление Сервисом и поддерживающее его работоспособность.</p>
                <p>1.3. Сервис — Сайт и Телеграм-бот, обеспечивающие взаимодействие с искусственным интеллектом для генерации контента на основе разработанных Администратором инструкций (промптов) и данных, предоставленных Пользователем.</p>
                <p>1.4. Искусственный интеллект - комплекс технологических решений включает в себя информационно-коммуникационную инфраструктуру, программное обеспечение (в том числе, в котором используются методы машинного обучения), процессы и сервисы по обработке данных, анализу и синтезу решений.</p>
                <p>1.5. Учетная запись — хранимая в базе данных Администратора совокупность данных о Пользователе, необходимых для его опознавания (аутентификации) и предоставления доступа к его личным данным и настройкам.</p>
                <p>1.6. Регистрация — передача персональных данных Пользователя Администратору в обмен на доступ к функционалу Сервиса.</p>
                <p>1.7. Запрос — процесс передачи сообщения от Пользователя Искусственному интеллекту с последующим получением Сгенерированного контента на основании сообщения Пользователя.</p>
                <p>1.8. Токен — наименьшая единица информации, на которую может быть разбито слово или фраза при обработке Искусственным интеллектом.</p>
                <p>1.9. Сгенерированный контент — текст, медиа- и другие файлы, сгенерированные в результате работы Сервиса по запросу Пользователя.</p>

                <h2>2. Общие положения</h2>

                <p>2.1. Настоящая Оферта является публичной офертой в соответствии со ст. 437 Гражданского кодекса Российской Федерации. Использование Сервиса означает полное и безоговорочное принятие Пользователем всех условий Оферты.</p>
                <p>2.2. Администратор оставляет за собой право вносить изменения в Оферту в одностороннем порядке. Изменения вступают в силу с момента их публикации на <a href="https://штучка-интеллект.рф/offer">https://штучка-интеллект.рф/offer</a>.</p>
                <p>2.3 Действующая редакция Оферты размещена в сети Интернет по адресу: <a href="https://штучка-интеллект.рф/offer">https://штучка-интеллект.рф/offer</a>.</p>
                
                <h2>3. Условия использования Сервиса</h2>

                <p>3.1. Администратор предоставляет Пользователю доступ к Сервису, разработанному и обслуживаемому Администратором, а Пользователь оплачивает доступ к Сервису в соответствии с выбранным тарифом либо пользуется бесплатно с ограничением функционала согласно тарифным планам, расположенным по адресу: <a href="https://штучка-интеллект.рф/price">https://штучка-интеллект.рф/price</a></p>
                <p>3.2. Сервис предоставляется «как есть» («as is»). Администратор не гарантирует соответствие возможностей Сервиса ожиданиям Пользователя, бесперебойную и безошибочную работу его функций или доступность в любое время, а также не предоставляет никаких иных гарантий, прямо не указанных в Оферте.</p>
                <p>3.3. Получение доступа к функционалу Сервиса в любой форме означает, что Пользователь принимает все условия Оферты в полном объёме без каких-либо ограничений. Если Пользователь не согласен с каким-либо положением Оферты и/или отдельной ее части или по каким-либо причинам он не имеет права на ее заключение, он обязан незамедлительно прекратить использование Сервиса. Оферта заключается непосредственно в момент регистрации Пользователя в Сервисе и действует на протяжении всего срока использования Сервиса при условии надлежащего выполнения Пользователем условий Оферты.</p>
                <p>3.4. Пользуясь Сервисом, Пользователь соглашается с тем, что неотъемлемыми частями настоящего Соглашения является Политика обработки персональных данных, размещенная по адресу <a href="https://штучка-интеллект.рф/privacy">https://штучка-интеллект.рф/privacy</a>.</p>
                <p>3.5. Пользователь обязуется не использовать Сервис в целях, противоречащих законодательству Российской Федерации.</p>

                <h2>4. Основные права и обязанности субъекта персональных данных.</h2>

                <p>4.1. Субъект персональных данных имеет право:</p>
                <p>4.1.1. использовать Сервис для получения следующей информации:</p>
                <ul>
                    <li>незаконной и/или запрещенной для распространения информации;</li>
                    <li>информации, содержащей советы, инструкции и указания для обхода законов Российской Федерации и нарушения прав третьих лиц;</li>
                    <li>неэтичной или оскорбительной для отдельных лиц или категорий лиц информации;</li>
                    <li>информации, разжигающую межнациональную рознь, пропагандирующей ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному и иным признакам, порочащей честь, достоинство и/или деловую репутацию третьих лиц;</li>
                    <li>порнографические материалы любого характера;</li>
                    <li>информации, нарушающей права на частную жизнь, содержащей персональные данные лиц, а также иной информации ограниченного доступа, включая коммерческую, банковскую, налоговую, семейную медицинскую тайну, тайну корреспонденции и т.д.;</li>
                    <li>информации, нарушающей авторские права, права на товарные знаки, средства индивидуализации и/или права на иные объекты интеллектуальной собственности, принадлежащие третьим лицам;</li>
                </ul>
                <p>4.1.2. нарушать нормальную работу Сервиса, в том числе обходить установленные лимиты использования Сервиса, ограничения на генерацию отдельного контента, направлять автоматические запросы, совершать иные действия, способные привести к функциональным сбоям и нарушению прав и интересов Администратора и третьих лиц;</p>
                <p>4.1.3 запрашивать, загружать, посылать, передавать или любым другим способом размещать и/или распространять посредством Сервиса контент, который является незаконным, вредоносным, клеветническим, нарушает права интеллектуальной собственности, оскорбляет нравственность, демонстрирует или пропагандирует насилие и жестокость, ненависть и/или дискриминацию по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ, оружия;</p>
                <p>4.1.4. нарушать своими действиями права третьих лиц и/или причинять им вред в любой форме;</p>
                <p>4.1.5. выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников Администратора, за владельца Сервиса, а также применять любые другие формы и способы незаконного представительства других лиц в сети Интернет, вводить других пользователей Сервиса в заблуждение относительно свойств и характеристик Сервиса;</p>
                <p>4.1.6. запрашивать, загружать, посылать, передавать или любым другим способом размещать и/или распространять посредством Сервиса или Сайта контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям;</p>
                <p>4.1.7. запрашивать, загружать, посылать, передавать или любым другим способом размещать и/или распространять, генерировать посредством Сервиса неразрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), схемы «пирамид», многоуровневого (сетевого) маркетинга (MLM), системы интернет-заработка, «письма счастья», а также использовать Сервис для участия в этих мероприятиях;</p>
                <p>4.1.8. запрашивать, загружать, посылать, передавать или любым другим способом размещать и/или распространять посредством Сервиса какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения, уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа, а также серийные номера к коммерческим программным продуктам и программы для их генерации, логины, пароли и прочие средства для получения несанкционированного доступа к платным ресурсам в Интернете, а также размещения ссылок на вышеуказанную информацию;</p>
                <p>4.1.9. несанкционированно собирать и хранить персональные данные других лиц;</p>
                <p>4.1.10. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Офертой;</p>
                <p>4.1.11. воспроизводить, повторять и копировать, продавать и перепродавать, а также использовать для каких-либо коммерческих целей какие-либо части Сервиса, или доступ к ним, кроме тех случаев, когда Пользователь получил такое разрешение от Администратора;</p>
                <p>4.1.12. использовать Сгенерированный контент и/или иной результат работы Сервиса в недобросовестных целях, в частности, вводить третьих лиц в заблуждение и выдавать Сгенерированный контент за результат человеческого труда, в тех случаях, когда не подразумевается или введен прямой запрет на использование технологий искусственного интеллекта (например, создание конкурсных или научных работ, выполнение договоров авторского заказа и др.);</p>
                <p>4.1.13. другим образом нарушать нормы законодательства, в том числе нормы международного права.</p>
                <p>4.2. При использовании Учетной записи Пользователь не вправе:</p>
                <p>4.2.1. осуществлять использование Учетной записи c нарушением требований, установленных настоящим Соглашением;</p>
                <p>4.2.2. передавать средства доступа для Учетной записи третьему лицу, а также уступать, продавать, производить обмен, совершать любые иные сделки предметом которых является Учетная запись и/или доступ к ней;</p>
                <p>4.2.3. вводить пользователей в заблуждение относительно принадлежности Учетной записи какому-либо лицу или организации;</p>
                <p>4.2.4. использовать Учетную запись с целью совершения незаконных действий.</p>

                <h2>5. Стоимость, порядок оплаты и возврата</h2>

                <p>5.1. Администратор вправе временно или окончательно приостановить доступ Пользователя к Сервису в случае нарушения условий настоящей Оферты.</p>
                <p>5.2. Администратор вправе заблокировать или удалить Учетную запись Пользователя, а также запретить доступ с использованием Учетной записи или устройства Пользователя к Сервису и/или Сайту (или к отдельным функциям Сервиса, если это возможно технологически) без объяснения причин, в том числе в случаях:</p>
                <p>5.2.1. выявления подозрительной активности Пользователя (в том числе регистрации нескольких учетных записей с одного IP-адреса);</p>
                <p>5.2.2. если действия Пользователя или размещаемая им информация нарушают условия Оферты и/или применимое законодательство;</p>
                <p>5.2.3. нарушение Пользователем прав и законных интересов Администратора, других Пользователей, третьих лиц, либо создании угроз такого нарушения.</p>
                <p>5.2.4. неиспользования Пользователем, в том числе для получения доступа к Сервису, в течение 1 (одного) года.</p>

                <h2>6. Стоимость, порядок оплаты и возврата</h2>

                <p>6.1. Сервис может предоставлять как бесплатные, так и платные функции. Информация о стоимости и порядке оплаты платных услуг предоставляется на сайте <a href="https://штучка-интеллект.рф">https://штучка-интеллект.рф</a> и в соответствующем разделе Сервиса.</p>
                <p>6.2. Стоимость пользования Сервисом определяется количеством потраченных токенов, которые можно приобрести в соответствующем разделе Сервиса.</p>
                <p>6.3. Приобретенные токены расходуются при каждом Запросе в количестве, определяемом Искусственным интеллектом на основании сложности Запроса и генерации ответа на Запрос. Стоимость генерации рассчитывается исходя из количества потраченных токенов. Среднее количество токенов, необходимых для Запроса, указано в интерфейсе Сервиса для каждого Запроса. Администратору заранее неизвестно количество токенов, необходимое для Запроса, поскольку расчет количества токенов производится Искусственным интеллектом по результату выполнения Запроса.</p>
                <p>6.4. Стоимость токена определяется и может быть изменена Администратором в одностороннем порядке и указана в разделе «Цены» по адресу <a href="https://штучка-интеллект.рф/price">https://штучка-интеллект.рф/price</a>. Изменения вступают в силу с момента опубликования на официальном сайте Администратора <a href="https://штучка-интеллект.рф/price">https://штучка-интеллект.рф/price</a>.</p>
                <p>6.5. Администратор может начислять Пользователю бесплатные токены при регистрации, актуальное количество которых указано на Сайте по адресу <a href="https://штучка-интеллект.рф/price">https://штучка-интеллект.рф/price</a>. Администратор также по своему усмотрению может начислять токены отдельным Пользователям в рамках акций или специальных предложений.</p>
                <p>6.6. Оплата токенов производится Пользователем в валюте Российской Федерации (рубль) путем перечисления безналичных денежных средств на расчетный счет Администратора.</p>
                <p>6.7. Возврат денежных средств за оказанные платные услуги осуществляется в соответствии с законодательством Российской Федерации.</p>

                <h2>7. Ответственность сторон</h2>

                <p>7.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием Сервиса, в том числе если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании Сервиса.</p>
                <p>7.2. Пользователь самостоятельно несет ответственность за соответствие предоставляемых Сервису данных законодательству, а также принимает на себя все риски при использовании им Сгенерированного контента, в том числе связанные с нарушением прав третьих лиц;</p>
                <p>7.3. Сгенерированный контент создается с использованием технологий искусственного интеллекта, в связи с чем Сгенерированный контент может не обладать уникальностью и совпадать полностью или частично со Сгенерированным контентом, созданным (сгенерированным) по Запросам других Пользователей.</p>
                <p>7.4. Используя Сгенерированный контент в своих целях, Пользователь принимает на себя все риски, в том числе связанные с нарушением каких-либо прав третьих лиц.</p>

                <h2>8. Ограничение ответственности</h2>

                <p>8.1. Администратор не несет ответственность за любые сбои или перерывы в работе Сервиса, вызванные техническими причинами, а также за убытки, возникшие у Пользователя в результате использования или невозможности использования Сервиса.</p>
                <p>8.2. Администратор не гарантирует точность и полноту информации, предоставляемой Сервисом, поскольку Искусственный интеллект работает на основе алгоритмов машинного обучения и может генерировать ответы, основанные на ограниченных данных.</p>
                <p>8.3. Администратор не связан с администрацией сервисов, предоставляющих функционал Искусственного интеллекта, и не несет ответственность за перебои в их работе или прекращение их работы по любой причине.</p>

                <h2>9. Персональные данные</h2>

                <p>9.1. При использовании Сервиса Пользователь соглашается на обработку его персональных данных в соответствии с Политикой в отношении обработки персональных данных, размещённой на Сайте Администратора.</p>
                <p>9.2. Администратор обязуется не передавать персональные данные третьим лицам, за исключением случаев, предусмотренных законодательством.</p>


                <h2>10. Заключительные положения</h2>

                <p>10.1. Настоящая Оферта регулируется законодательством Российской Федерации.</p>
                <p>10.2. Все споры и разногласия, возникающие в связи с исполнением настоящей Оферты, разрешаются путем переговоров. В случае невозможности достичь соглашения споры подлежат рассмотрению в суде по месту нахождения Администратора.</p>
                <p>10.3. Принятие условий Оферты осуществляется посредством постановки галочки в соответствующем поле при регистрации на Сайте или посредством нажатия кнопки «Начать» в Телеграм-боте или посредством запуска мини-приложения в Телеграм.</p>
                <br />
                <p>Индивидуальный предприниматель<br />Ложкина Вера Александровна</p>
                <p>ИНН 860225526635<br />ОГРНИП 319861700052149<br />Электронная почта: <a href="mailto:akane@inbox.ru">akane@inbox.ru</a></p>
            </div>
        </div>
    );
};

export default Offer;