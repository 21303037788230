import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './Tariffs.css'
import { formatDate } from '../utils/formatDateTime';
import { formatTimeToMoscow } from '../utils/formatDateTime';
import { Context } from '../context/GlobalContext';
import { useSearchParams } from 'react-router-dom';

const Tariffs = () => {
    const navigate = useNavigate();
    const [tokensData, setTokensData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const { user } = useContext(Context);

    const [activeTab, setActiveTab] = useState('tab1');
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Read the 'tab' query parameter from the URL
        const tabFromUrl = searchParams.get('tab');
        if (tabFromUrl) {
          setActiveTab(tabFromUrl);
        }
      }, [searchParams]);

    useEffect(() => {
        fetchTokensData();
    }, []);

    const fetchTokensData = async () => {

        if (!user || !user.id) {
            console.error('User ID is missing');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/thingy-tokens?userId=${user.id}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setTokensData(data);
        } catch (error) {
            console.error('Error fetching tokens data:', error);
        }
    };

    const handleGetTokens = () => {
        window.open(`/tokens?re=tokens&targetId=${user.id}`, '_blank');
        navigate('/wait');

        /*if (window.Telegram.WebApp) {
            window.Telegram.WebApp.close();
        }*/
    };

    const handleShare = async () => {
        const url = `https://t.me/aithingybot/aithingy&startapp=${user.id}`;
        if (navigator.share) {
            // Native share supported
            try {
                await navigator.share({
                    title: 'Check this out!',
                    text: 'I found something interesting:',
                    url: url,
                });
                console.log('Content shared successfully!');
            } catch (err) {
                console.error('Error sharing:', err);
            }
        } else {
            // Fallback to copying the link
            try {
                await navigator.clipboard.writeText(url || window.location.href);
                alert('Link copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy the link:', err);
                alert('Failed to copy the link. Please copy it manually.');
            }
        }
    };

    return (
        <div className='wrapper'>
            <div style={{ height: '50px' }}></div>
            <div className='wrapper1'>
                <div className="tab-block-header" onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginBottom: '20px' }}>
                    <div style={{ lineHeight: '0' }}><img style={{ marginRight: '10px', width: '24px', height: '24px' }} src='/icons/arrow-left.png' /></div>
                    <div>Назад</div>
                </div>
                {/* Tabs Navigation */}
                <div style={{ display: 'flex', gap: '20px', marginTop: '10px', marginBottom: '20px' }}>
                    <button
                        onClick={() => setActiveTab('tab1')}
                        className={`tariffs-tab-item ${activeTab === 'tab1' ? 'active' : ''}`}
                    >
                        Баланс
                    </button>
                    <button
                        onClick={() => setActiveTab('tab2')}
                        className={`tariffs-tab-item ${activeTab === 'tab2' ? 'active' : ''}`}
                    >
                        Рефералка
                    </button>
                </div>
                <div>
                    {activeTab === 'tab1' && (
                        <div>
                            <h1>Ваш баланс</h1>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}><span><img src="/icons/coin.png" style={{ width: '20px', transform: 'translateY(2px)' }} alt="coin" /></span><span style={{ fontSize: '26px', fontWeight: '500' }}>{user.token_amount}</span></div>
                            <button className='action-button' style={{ width: '100%' }} onClick={handleGetTokens}>Получить токены</button>
                            <div style={{ marginTop: '30px', marginBottom: '20px', fontSize: '18px', fontWeight: '500' }}>История транзакций:</div>
                            <div className="tokens-container">
                                {tokensData.map((transaction, index) => (
                                    <div className="token-card" key={index}>
                                        <div className='token-card-left'>
                                            <div style={{ fontSize: '16px', fontWeight: '500' }}>{transaction.thingy_name}</div>
                                            <p>{transaction.project}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <p style={{
                                                    marginRight: '20px',
                                                    marginLeft: '10px',
                                                    color: transaction.inout === 'in' ? 'green' : 'red',
                                                    fontWeight: '500',
                                                    fontSize: '14px',
                                                }}>
                                                    {transaction.inout === 'in' ? '+' : '-'}{transaction.tokens}</p>
                                            </div>
                                            <div>
                                                <p style={{ opacity: '0.7', fontWeight: '400', fontSize: '12px' }}>{formatDate(transaction.date_created)}</p>
                                                <p style={{ opacity: '0.7', fontWeight: '400', fontSize: '12px' }}>{formatTimeToMoscow(transaction.time_created)} МСК</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {activeTab === 'tab2' && (
                        <div>
                            <h2 style={{fontSize: '24px', marginBottom: '20px'}}>Ваш реферальный баланс</h2>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}><span><img src="/icons/coin.png" style={{ width: '20px', transform: 'translateY(2px)' }} alt="coin" /></span><span style={{ fontSize: '26px', fontWeight: '500' }}>{user.referral_bonus}</span></div>
                            <button className='action-button' style={{ width: '100%' }} onClick={handleShare}>Поделиться штучкой</button>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Tariffs;
