import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { updateProject } from '../utils/api';
import { Context } from '../context/GlobalContext';
import ConfirmationModal from '../components/ConfirmationModal';
import './CreateProject.css';

const EditProject = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { projectId, projectName: initialProjectName, projectDescription: initialProjectDescription } = location.state || {};

    const { user, setProjects, setSelectedProject } = useContext(Context);

    const [projectName, setProjectName] = useState(initialProjectName || '');
    const [projectDescription, setProjectDescription] = useState(initialProjectDescription || '');
    const [showModal, setShowModal] = useState(false);

    const maxNameLength = 30;
    const maxDescriptionLength = 2500;
    const [isFieldFocused, setIsFieldFocused] = useState(false);
    const bottomRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(() => {
        autoResizeTextarea();
    }, [projectDescription]);

    const handleUpdate = async () => {
        try {
            const response = await updateProject({
                name: projectName,
                description: projectDescription,
                user_id: user.id,
                project_id: projectId,
                is_active: true
            });

            if (response && response.message === 'OK') {

                // Update the projects in the context
                setProjects(prevProjects =>
                    prevProjects.map(project =>
                        project.id === projectId ? { ...project, name: projectName, description: projectDescription } : project
                    )
                );

                // Set the updated project as the selected project
                setSelectedProject({ id: projectId, name: projectName, description: projectDescription });

                navigate('/projects');
            }
        } catch (error) {
            alert('Failed to update project');
        }
    };

    const handleDeleteProject = async () => {
        try {
            const response = await updateProject({
                name: projectName,
                description: projectDescription,
                user_id: user.id,
                project_id: projectId,
                is_active: false
            });

            if (response && response.message === 'OK') {

                setProjects(prevProjects =>
                    prevProjects.filter(project => project.id !== projectId)
                );

                setSelectedProject(null);

                navigate('/projects');
            }
        } catch (error) {
            alert('Failed to delete project');
        }
    };

    const handleDescriptionChange = (e) => {
        setProjectDescription(e.target.value);
        autoResizeTextarea();
    };

    const autoResizeTextarea = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'; // Reset the height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
        }
    };

    const handleNameChange = (e) => {
        setProjectName(e.target.value)
    };

    const handleFocus = () => {
        setIsFieldFocused(true);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    const handleBlur = () => {
        setIsFieldFocused(false);
    };

    return (
        <div className='wrapper' ref={bottomRef}>
            <div className='padding-top'></div>
            <div className='wrapper1'>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdate();
                    }}
                >
                    <div className="tab-block-header" onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginBottom: '20px' }}>
                        <div style={{lineHeight: '0'}}><img style={{ marginRight: '10px', width: '24px', height: '24px' }} src='/icons/arrow-left.png' /></div>
                        <div>Назад</div>
                    </div>
                    <h1>Добавьте знание о вашем проекте</h1>
                    <div className="form-group" style={{ marginTop: '0px' }}>
                        <label htmlFor="projectName">Название проекта</label>
                        <div className='input-wrapper' style={{ position: 'relative' }}>
                            <input className='create-project-input'
                                type="text"
                                id="projectName"
                                value={projectName}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleNameChange}
                                maxLength={maxNameLength}
                                placeholder="Мой проект"
                                required
                            />
                            <span
                                className="remaining-characters"
                                style={{
                                    position: 'absolute',
                                    bottom: '5px',
                                    right: '10px',
                                    fontSize: '12px',
                                    color: '#999'
                                }}
                            >
                                {maxNameLength - projectName.length}/{maxNameLength}
                            </span>
                        </div>
                        <div className='label-description'>Для списка проектов</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="projectDescription"><span>Описание проекта </span></label>
                        <div className='input-wrapper' style={{ position: 'relative' }}>
                            <textarea className='create-project-input1'
                                id="projectDescription"
                                value={projectDescription}
                                ref={textareaRef}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleDescriptionChange}
                                maxLength={maxDescriptionLength}
                                placeholder="Описание моего проекта"
                                style={{ overflow: 'hidden', resize: 'none' }}
                                required
                            />
                            <span
                                className="remaining-characters"
                                style={{
                                    position: 'absolute',
                                    bottom: '5px',
                                    right: '10px',
                                    fontSize: '12px',
                                    color: '#999'
                                }}
                            >
                                {maxDescriptionLength - projectDescription.length}/{maxDescriptionLength}
                            </span>
                        </div>
                        <div className='label-description'>Это знание будет учитываться при генерации контента</div>
                    </div>
                    <button type="submit" className="action-button">Сохранить</button>
                    <button
                        type="button"
                        ref={bottomRef}
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            border: 'none',
                            background: 'none',
                            textDecoration: 'underline',
                            color: 'red', marginTop: '20px',
                            fontSize: '16px',
                            fontWeight: '500',
                            marginBottom: isFieldFocused ? '400px' : '0px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setShowModal(true)}
                    >
                        Удалить
                    </button>
                </form>

                {showModal && (
                    <ConfirmationModal
                        title="Точно удалить?"
                        text="Нельзя вернуть то, что удалено"
                        onConfirm={handleDeleteProject}
                        onCancel={() => setShowModal(false)}
                        closeModal={() => setShowModal(false)}
                        cancelLabel="Нет"
                        confirmLabel="Да"
                    />
                )}
            </div>
        </div>
    );
};

export default EditProject;
