import React from 'react';
import { useNavigate } from 'react-router-dom';

const Wait = () => {
    const navigate = useNavigate();

    const handleReload = () => {
        window.location.href = '/';
    };

    return (
        <div className='wrapper'>
            <div className='wrapper1' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <h2 style={{textAlign: 'center'}}>После оплаты нужно перезагрузить приложение</h2>
                <button className='action-button' onClick={handleReload}>Перезагрузить</button>
            </div>
        </div>
    );
};

export default Wait;
