export function formatDate(dateString) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', options);
};

export function formatTimeToMoscow(timeString) {
    // Create a new Date object from the time string
    const [hours, minutes, seconds] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds.split('.')[0]); // Only take the seconds without the fraction

    // Format to Moscow time
    const options = { timeStyle: 'short', timeZone: 'Europe/Moscow' }; // Format as needed
    return date.toLocaleTimeString('ru-RU', options);
};